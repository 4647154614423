import React, { createContext, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { getCookie } from "../utils/api";
import LoginPopup from "../components/LoginPopup/LoginPopup";

const PopupContext = createContext();

export function PopupProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    const path = location.pathname;

    if (
      path === "/" ||
      path === "/register" ||
      path === "/reset-password" ||
      path === "/verify-phone" ||
      path === "/mfa" ||
      path.includes("verify") ||
      path.includes("forgotuserpassword")
    ) {
      return;
    }
    const storedTime = getCookie("apiKeyExpirationUTCDate");
    if (!storedTime || `${storedTime}` === "null") {
      console.log("api key expiration date not present");
      navigate("/");
      return;
    }

    const currentTimeUTC = new Date().toUTCString();
    const storedTimeUTC = new Date(storedTime + "Z").toUTCString();
    const difference =
      new Date(storedTimeUTC).getTime() - new Date(currentTimeUTC).getTime();

    if (getCookie("apiKey") && getCookie("apiKey") !== "undefined") {
      setOpen(true);
      return true;
    } else {
      navigate("/");
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <PopupContext.Provider value={{ open, handleOpen, handleClose }}>
      {children}
      <LoginPopup open={open} handleClose={handleClose} />
    </PopupContext.Provider>
  );
}

export function useLoginPopup() {
  return useContext(PopupContext);
}
