import md5 from "md5";
import { getCookie } from "./api";
import envConfig from "../config";

export const getLoginSecured = (loginEmail, loginPassword) => {
  const hashedPassword = md5(loginPassword);
  const combinedString = `${loginEmail}:${hashedPassword}`;
  const base64Encoded = btoa(combinedString);
  return base64Encoded;
};

export const LOGIN_API_OLIVS_APP_URL = `https://${envConfig.apiLogin}/api/v1/`;

export const createSessionForExternal = async (
  url,
  apikey,
  sessionId,
  loginSecuredHash
) => {
  if (url.startsWith(`https://${envConfig.loginUrl}`)) {
    window.location = url;
    return;
  }
  const newSessionApiUrl = `https://${envConfig.apiLogin}/api/v1/en-au/session-management/create-new-session-instance?BaseHostURL=${envConfig.mainServiceUrl}`;
  const apiKeyFromCookies = getCookie("apiKey") + "-" + getCookie("databaseId");

  const sessionHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    "olivs-api-real-ip": "127.0.0.2",
    "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
    "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
    "browser-agent": window.navigator.userAgent,
    "session-id": sessionId || getCookie("sessionId"),
    "api-key": apikey || apiKeyFromCookies,
  };

  const initialTokenData = JSON.stringify({
    LoginSecuredHash:
      loginSecuredHash ||
      getLoginSecured(getCookie("loginEmail"), getCookie("loginPassword")),
    CurrentHost: `https://${envConfig.mainServiceUrl}`,
    LoginPageUrl: `https://${envConfig.loginUrl}`,
    RedirectPageUrl: url,
    IpAddress: "127.0.0.2",
    UserAgentAkaBrowserDetails: navigator.userAgent,
    ReferrerUrl: window.location.origin,
    DatabaseId: getCookie("databaseId") || 0,
  });
  const sessionManagementResponse = await fetch(newSessionApiUrl, {
    method: "POST",
    headers: sessionHeaders,
    body: initialTokenData,
  });

  const sessionManagementJson = await sessionManagementResponse.json();
  if (sessionManagementJson?.successYN === "Y") {
    window.location = sessionManagementJson?.redirectPageUrl;
  } else {
    console.log(sessionManagementJson);
    console.log("nav went wrong");
  }
};

const cookiesToDelete = [
  "loginEmail",
  "loginPassword",
  "sessionGuid",
  "globalUserId",
  "databaseId",
  "login2FASecKey",
  "login2FaCodeLastUsedUTCDate",
  "loginLastSec2CheckInUTCDate",
  "loginLastPwdUTCDate",
  "apiKeyExpirationUTCDate",
  "sessionId",
  "apiKey",
];

export const deleteAllCookies = () => {
  cookiesToDelete.forEach((cookieName) => {
    document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
  });
};
