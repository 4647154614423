import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTheme } from "../../../context/ThemeContext";
import {
  LOGIN_API_OLIVS_APP_URL,
  createSessionForExternal,
} from "../../../utils/common";
import Logo from "../../../assets/exacc-logo.png";
import envConfig from "../../../config";
import { enqueueSnackbar } from "notistack";

const VerifyPhone = () => {
  const { isDarkMode } = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [mobilePhoneNumber, setMobilePhoneNumber] = useState("");
  const [phoneNumberSaved, setPhoneNumberSaved] = useState("");
  const [code, setCode] = useState();
  const [sessionId, setSessionId] = useState("");
  const [loginSecuredHash, setLoginSecuredHash] = useState();
  const [apiKey, setApiKey] = useState();

  const [updatingPhone, setUpdatingPhone] = useState(false);
  const [phoneChecked, setPhoneChecked] = useState(false); // Add this state to track if the phone number has been checked

  const handleUpdate = async (e) => {
    e.preventDefault();

    if (!mobilePhoneNumber || mobilePhoneNumber?.length < 5) {
      enqueueSnackbar("Invalid phone number.", { variant: "error" });
      return;
    }
    if (!mobilePhoneNumber?.startsWith("+61")) {
      enqueueSnackbar("Mobile phone number needs to start with +61", {
        variant: "error",
      });
      return;
    }

    try {
      const response = await fetch(
        `https://${envConfig.apiDev1}/api/v3/en-au/users/change-phone-number?BaseHostURL=${envConfig.loginUrl}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "olivs-api-real-ip": "127.0.0.2",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "api-key": "OlivsAuth",
          },
          body: JSON.stringify({
            LoginSecuredHash: loginSecuredHash,
            newMobilePhoneNumber: mobilePhoneNumber,
          }),
        }
      );
      const data = await response.json();

      if (data.SuccessYN === "N") {
        enqueueSnackbar(
          data.ErrorMessage || "Something went wrong. Please try again",
          { variant: "error" }
        );
      } else if (data.SuccessYN === "Y") {
        setPhoneNumberSaved(mobilePhoneNumber);
        setUpdatingPhone(false);
        enqueueSnackbar("Your phone number has been updated", {
          variant: "success",
        });
        setMobilePhoneNumber("");
      }
    } catch (error) {
      console.error("An error occurred while updating:", error);
      enqueueSnackbar(error || "Something went wrong. Please try again", {
        variant: "error",
      });
    }
  };

  useEffect(() => {
    if (!phoneChecked) {
      console.log(location?.state?.phone);
      console.log(phoneNumberSaved);
      console.log(phoneChecked);
      // Only run this block if phoneChecked is false
      if (
        location.state?.phone &&
        location.state?.apiKey &&
        !phoneNumberSaved
      ) {
        setPhoneNumberSaved(location.state.phone);
        setLoginSecuredHash(location.state.loginSecuredHash);
        setSessionId(location.state.sessionId);
        setApiKey(location.state.apiKey);
        setPhoneChecked(true);
      } else if (!location?.state?.phone && !phoneNumberSaved) {
        enqueueSnackbar("Phone number not found", { variant: "error" });
        navigate("/");
      }
    }
  }, [location.state]);

  const verifyAll = async () => {
    try {
      const allUrl = `https://${envConfig.apiDev1}/api/v3/en-au/users/activate-created?BaseHostURL=${envConfig.loginUrl}`;

      const headers = {
        Accept: "application/json",
        "Content-Type": "application/json",
        "olivs-api-real-ip": "127.0.0.2",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "api-key": "OlivsAuth",
      };

      const allResponse = await fetch(allUrl, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          LoginSecuredHash: loginSecuredHash,
        }),
      });

      const allJson = await allResponse.json();

      if (allJson?.SuccessYN === "Y" || allJson?.SuccesYN === "Y") {
        await createSessionForExternal(
          `https://${envConfig.correspondingService}/0/en-au/olivs/global-user-2fa-setup`,
          apiKey,
          sessionId,
          loginSecuredHash
        );
      } else {
        console.log("fail");
        enqueueSnackbar(allJson?.ErrorMessage || "Unexpected error occurred", {
          variant: "error",
        });
        throw new Error(allJson?.ErrorMessage || "Unexpected error occurred");
      }
    } catch (error) {
      console.error("Error during activation:", error);
      enqueueSnackbar(
        "Error occurred while approving the user. Please try again.",
        { variant: "error" }
      );
    }
  };

  const handleSubmit = async () => {
    if (!code || code?.length < 5) {
      enqueueSnackbar("Invalid verification code", { variant: "error" });
      return;
    }
    try {
      const verificationUrl =
        LOGIN_API_OLIVS_APP_URL +
        `en-au/system/validate-2fa-code?BaseHostURL=${envConfig.loginUrl}`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        "olivs-api-real-ip": "127.0.0.2",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "session-id": sessionId,
        "browser-agent": window.navigator.userAgent,
        "api-key": apiKey,
      };

      console.log("Making verification request with:", {
        verificationUrl,
        headers,
        code,
      });

      const verificationData = JSON.stringify({
        UserLogin2FACode: parseInt(code),
      });

      const verificationResponse = await fetch(verificationUrl, {
        method: "POST",
        headers: headers,
        body: verificationData,
      });

      const verificationJson = await verificationResponse.json();
      if (verificationJson.SuccessYN === "Y") {
        const approveUrl = `https://${envConfig.apiDev1}/api/v3/en-au/users/approve-phone-number?BaseHostURL=${envConfig.loginUrl}`;

        const approveHeaders = {
          Accept: "application/json",
          "Content-Type": "application/json",
          "olivs-api-real-ip": "127.0.0.2",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": "OlivsAuth",
        };

        console.log("Making approve request with:", {
          approveUrl,
          approveHeaders,
          loginSecuredHash,
        });

        const approveResponse = await fetch(approveUrl, {
          method: "POST",
          headers: approveHeaders,
          body: JSON.stringify({
            LoginSecuredHash: loginSecuredHash,
          }),
        });

        const approveJson = await approveResponse.json();
        if (approveJson?.SuccessYN === "Y") {
          enqueueSnackbar(
            "Your phone number has been verified. You will soon be redirected",
            { variant: "success" }
          );
          await verifyAll();
        } else {
          enqueueSnackbar(approveJson?.ErrorMessage, { variant: "error" });
        }
      } else {
        enqueueSnackbar(verificationJson.ErrorMessage, { variant: "error" });
      }
    } catch (error) {
      console.error("Error during verification:", error);
      enqueueSnackbar(
        "Error occurred while processing the code. Please try again.",
        { variant: "error" }
      );
    }
  };

  const resendCode = async () => {
    try {
      const smsUrl = `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/send-code-as-sms?BaseHostURL=${envConfig.loginUrl}`;

      const headers = {
        Accept: "application/json",
        "olivs-api-real-ip": "127.0.0.2",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "browser-agent": window.navigator.userAgent,
        "session-id": sessionId,
        "api-key": apiKey,
      };

      const smsResponse = await fetch(smsUrl, {
        method: "POST",
        headers: headers,
      });

      const smsJson = await smsResponse.json();
      if (smsJson?.SmsSentYN === "Y") {
        enqueueSnackbar("SMS has been sent.", { variant: "success" });
      } else {
        enqueueSnackbar(smsJson?.ErrorMessage, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(
        "Error occurred while sending the code. Please try again.",
        { variant: "success" }
      );
    }
  };

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <img
        onClick={() => {
          navigate(`/`);
        }}
        height={50}
        style={{ cursor: "pointer", position: "absolute", top: "64px" }}
        src={Logo}
        alt="Logo"
      />
      <Box sx={{ mt: "150px" }}>
        <form>
          <Typography
            variant="h4"
            component="h1"
            sx={{
              color: isDarkMode ? "#7D8CAA" : "#495670",
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: "Inter",
              fontSize: "28px",
              fontStyle: "normal",
              fontWeight: 300,
              lineHeight: "133.4%",
            }}
          >
            Create Your Account
          </Typography>
          <Box
            sx={{
              mt: isMobile
                ? "20px"
                : isTablet
                ? "30px"
                : noFullFit
                ? "70px"
                : "100px",
              borderBottom: "1px solid #EDEDED",
              paddingBottom: "39px",
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
          >
            <Typography sx={{ mb: 3 }}>
              Your e-mail address is verified!
            </Typography>
            <Typography sx={{ mb: 2 }}>
              Now, let’s verify your phone number: we’ve sent a text message
              (SMS) with verification code to your phone
              {` (${phoneNumberSaved})`}. Please enter it below.
            </Typography>
            {!updatingPhone ? (
              <Box sx={{ mb: 1 }}>
                <Link
                  className="link"
                  onClick={() => setUpdatingPhone(true)}
                  style={{
                    color: isDarkMode ? "#AAD269" : "#849F23",
                    marginTop: 0,
                    cursor: "pointer",
                    fontSize: "14px",
                    fontWeight: 700,
                    letterSpacing: "0.4px",
                    textTransform: "capitalize",
                    textDecoration: "none",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  Edit Phone Number
                </Link>
              </Box>
            ) : (
              <Box sx={{ display: "flex", justifyItems: "center" }}>
                <TextField
                  name="mobilePhoneNumber"
                  variant="outlined"
                  value={mobilePhoneNumber}
                  onChange={(e) => setMobilePhoneNumber(e.target.value)}
                  margin="normal"
                  label="Mobile Phone Number"
                  required
                  sx={{
                    width: 240,
                    mt: 0,
                    mb: 0,
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                />

                <Button
                  sx={{ height: "40px", mr: 1, ml: 1 }}
                  variant="secondary"
                  onClick={handleUpdate}
                >
                  Update
                </Button>
                <Button
                  sx={{ height: "40px", mr: 1 }}
                  variant="tertiary"
                  onClick={() => setUpdatingPhone(false)}
                >
                  Cancel
                </Button>
              </Box>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              label="Verification Code"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              required
              sx={{
                width: isMobile ? "100%" : isTablet ? 380 : 480,
                mb: 0,
                label: { fontSize: 16, marginTop: 0 },
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              fullWidth
              sx={{
                mt: 6,
                mb: 0,
                width: isMobile ? "100%" : isTablet ? 380 : 480,
              }}
            >
              Sign Up
            </Button>
          </Box>
          <Box
            sx={{
              mt: 2,
              mb: 4,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: isMobile ? "100%" : isTablet ? 380 : 480,
            }}
          >
            <Typography
              sx={{
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              SMS didn't arrive?
            </Typography>
            <Link
              onClick={resendCode}
              className="link"
              style={{
                color: isDarkMode ? "#AAD269" : "#849F23",
                marginLeft: 10,
                marginTop: 0,
                cursor: "pointer",
                fontSize: "14px",
                fontWeight: 700,
                letterSpacing: "0.4px",
                textTransform: "capitalize",
                textDecoration: "none",
              }}
            >
              Resend the SMS
            </Link>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default VerifyPhone;
