import {
  Typography,
  Box,
  TextField,
  Switch,
  FormControlLabel,
  Button,
  Autocomplete,
  Checkbox,
  Dialog,
  DialogContent,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HelpTooltip from "../../components/HelpTooltip/HelpTooltip";
import { useEffect, useRef, useState } from "react";
import { useTheme } from "../../context/ThemeContext";
import ReCAPTCHA from "react-google-recaptcha";
import { commonThemes } from "../../utils/themes/themes";
import { ReactComponent as ArrowBack } from "../../assets/arrow_back.svg";
import useApiCall from "../../hooks/ApiCall";
import useApiCallDefault from "../../hooks/ApiCallDefault";
import { default_headers, getHeaders } from "../../utils/api";
import LookupAbn from "../../components/LookupAbn/LookupAbn";
import { enqueueSnackbar } from "notistack";
import envConfig from "../../config";
import { createSessionForExternal } from "../../utils/common";
import { CircularProgress } from "@mui/material";
import Cookies from "js-cookie";
import { checkAndUpdateApiKeyExpiration } from "../../components/Dashboard/Dashboard";

const AustralianBusinessNumber = (ABN) => {
  if (!ABN) return true;
  ABN = ABN.replace(/\s+/g, "");

  if (ABN.length !== 11) {
    return false;
  }

  if (isNaN(ABN)) {
    return false;
  }

  let T1 = (parseInt(ABN.substring(0, 1)) - 1) * 10;
  let T2 = parseInt(ABN.substring(1, 2)) * 1;
  let T3 = parseInt(ABN.substring(2, 3)) * 3;
  let T4 = parseInt(ABN.substring(3, 4)) * 5;
  let T5 = parseInt(ABN.substring(4, 5)) * 7;
  let T6 = parseInt(ABN.substring(5, 6)) * 9;
  let T7 = parseInt(ABN.substring(6, 7)) * 11;
  let T8 = parseInt(ABN.substring(7, 8)) * 13;
  let T9 = parseInt(ABN.substring(8, 9)) * 15;
  let T10 = parseInt(ABN.substring(9, 10)) * 17;
  let T11 = parseInt(ABN.substring(10, 11)) * 19;

  let Chk = T1 + T2 + T3 + T4 + T5 + T6 + T7 + T8 + T9 + T10 + T11;
  Chk = Chk / 89;
  if (Chk === Math.floor(Chk)) {
    return true;
  }

  return false;
};

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};

export const PageTitle = ({
  title = "",
  onBack,
  displayBackIcon,
  style = {},
}) => {
  const { isDarkMode } = useTheme();
  return (
    <Box sx={{ mt: 4, display: displayBackIcon ? "flex" : "block" }}>
      {displayBackIcon && (
        <Box sx={{ cursor: "pointer", mr: "10px" }} onClick={onBack}>
          <ArrowBack />
        </Box>
      )}
      <Typography
        sx={{
          ...commonThemes.pageTitle,
          color: isDarkMode ? "#FFF" : "rgb(73, 85, 112)",
          marginBottom: "40px",
          ...style,
        }}
      >
        {title}
      </Typography>
    </Box>
  );
};

const CreateBusinessAccount = ({ isCreatingSubscriber }) => {
  const location = useLocation();
  const recaptchaRef = useRef();
  const navigate = useNavigate();
  const { isDarkMode } = useTheme();

  const [isLoading, setIsLoading] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [isAbnOpen, setIsAbnOpen] = useState(false);
  const [abnApiResult, setAbnApiResult] = useState(null);
  const [abnResultsAccepted, setAbnResultsAccepted] = useState(null);
  const [selectedBusinessType, setSelectedBusinessType] = useState(null);

  const {
    data: subscribersData,
    error: subscribersError,
    loading: subscribersLoading,
  } = useApiCall({
    url: `https://${
      envConfig.apiDev1
    }/api/v1/en-au/subscribers/get-subscribers-list?LoginEmail=${getCookie(
      "loginEmail"
    )}&BaseHostURL=${envConfig.loginUrl}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "olivs-api-real-ip": "31.42.0.186",
      "olivs-api-computer-name": "BTM-WS2019",
      "session-id": getCookie("sessionId"),
      "browser-agent": window.navigator.userAgent,
      "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
      "api-key": getCookie("apiKey"),
    },
    navigate,
    skip: isCreatingSubscriber,
  });

  // const {
  //   data: countriesData,
  //   error,
  //   loading: countriesLoading,
  // } = useApiCallDefault(
  //   `https://${envConfig.apiDev1}/api/v3/en-au/countries/get-all?BaseHostURL=${envConfig.loginUrl}`,
  //   "GET",
  //   default_headers,
  //   null,
  //   navigate
  // );

  const {
    data: businessTypesData,
    error: businessTypesError,
    loading: businessTypesLoading,
  } = useApiCallDefault(
    `https://${envConfig.apiDev1}/api/v3/en-au/businesses/get-types?BaseHostURL=${envConfig.loginUrl}`,
    "GET",
    default_headers,
    null,
    navigate
  );

  const formSchema = Yup.object().shape({
    subscriberAccount: isCreatingSubscriber
      ? Yup.object().shape({
          xxsName: Yup.string(),
        })
      : Yup.object().shape({
          xxsName: Yup.string().required("Selecting a subscriber is required"),
        }),
    businessName: Yup.string().required("Business Name is required"),
    abn: isCreatingSubscriber
      ? Yup.string()
          .required("ABN is required")
          .test("valid-abn", "Invalid ABN", (value) =>
            AustralianBusinessNumber(value)
          )
      : Yup.string().test("valid-abn", "Invalid ABN", (value) =>
          AustralianBusinessNumber(value)
        ),
    businessOwnerEmail: Yup.string()
      .email("Invalid email")
      .required("Business Owner Email is required"),
    // contactName: Yup.string(),
    // contactEmail: Yup.string().email("Invalid email"),
    // contactPhone: Yup.string(),
    businessType: Yup.string().required("Business Type is required"),
    gstRegistered: Yup.boolean(),
    countryOfResidence: Yup.object().shape({
      ctrID: Yup.string(),
      ctrCode: Yup.string(),
      ctrName: Yup.string(),
      // ctrID: Yup.string().required("Country of Residence is required"),
      // ctrCode: Yup.string().required("Country of Residence is required"),
      // ctrName: Yup.string().required("Country of Residence is required"),
    }),
    allowTeamAccess: Yup.boolean(),
    allowTeamInviteExternal: Yup.boolean(),
    taxAgent: Yup.boolean(),
    registeredAgentNumber: Yup.string(),
    registeredAgentName: Yup.string(),
    registeredAgentEmailAddress: Yup.string().email("Invalid email"),
    registeredAgentPhoneNumber: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      subscriberAccount: "",
      businessName: "",
      abn: "",
      businessOwnerEmail: "",
      contactName: "",
      contactEmail: "",
      contactPhone: "",
      manageBusiness: false,
      businessType: "",
      gstRegistered: false,
      countryOfResidence: { ctrID: 14, ctrCode: "AUS", ctrName: "Australia" },
      allowTeamAccess: false,
      allowTeamInviteExternal: false,
      taxAgent: false,
      registeredAgentNumber: "",
      registeredAgentName: "",
      registeredAgentEmailAddress: "",
      registeredAgentPhoneNumber: "",
    },

    validationSchema: formSchema,
    onSubmit: async (values) => {
      if (!recaptchaValue) return;
      setIsLoading(true);
      const apiData = isCreatingSubscriber
        ? {
            DatabaseSubscriberID: values.subscriberAccount?.xxsID,
            BusinessName: values.businessName,
            BusinessTypeId: values.businessType,
            BusinessRegistered4GSTYN: values.gstRegistered ? "Y" : "N",
            AutoAccessYN: values.allowTeamAccess ? "Y" : "N",
            AllowTeamMembersInviteExternalUsersYN:
              values.allowTeamAccess && values.allowTeamInviteExternal
                ? "Y"
                : "N",
            // CountryId: values.countryOfResidence.ctrID,
            CountryId: 14, // australia
            TaxCode: values.abn.replace(/\s+/g, ""),
            ContactName: values.contactName,
            ContactPhoneNo: values.contactPhone,
            OwnerEmail: values.businessOwnerEmail,
            TaxAgentRAN: values.taxAgent ? values.registeredAgentNumber : "",
            TaxAgentName: values.taxAgent ? values.registeredAgentName : "",
            TaxAgentEmail: values.taxAgent
              ? values.registeredAgentEmailAddress
              : "",
            TaxAgentPhone: values.taxAgent
              ? values.registeredAgentPhoneNumber
              : "",
          }
        : {
            // CountryID: values.countryOfResidence.ctrID,
            CountryID: 14, // Australia
            BusinessTypeID: values.businessType,
            CompanyName: values.businessName,
            DatabaseOwnerEmail: values.businessOwnerEmail,
            CreatedByGlobalUserID: getCookie("globalUserId"),
            DatabaseSubscriberID: values.subscriberAccount?.xxsID,
            CompanyTaxCode: values.abn.replace(/\s+/g, ""),
            BusinessActivityCode: "n/a",
            BusinessRegistered4GSTYN: values.gstRegistered ? "Y" : "N",
            BillingSubscriderID: getCookie("globalUserId"),
            CurrentSubscriberIsAdminYN: "Y",
            CurrentSubscriberAutoAddYN: values.allowTeamAccess ? "Y" : "N",
            CurrentSubscriberCanAddOthersYN:
              values.allowTeamAccess && values.allowTeamInviteExternal
                ? "Y"
                : "N",
            IsDemoFile: false,
          };
      try {
        const controllerName = isCreatingSubscriber
          ? "subscribers"
          : "databases";
        const version = isCreatingSubscriber ? "v3" : "v4";

        const actionName = isCreatingSubscriber
          ? "create-subscriber-with-business-file"
          : "create-new-business-file";
        const url = `https://${envConfig.apiDev1}/api/${version}/en-au/${controllerName}/${actionName}?BaseHostURL=${envConfig.loginUrl}`;
        const response = await fetch(url, {
          method: "POST",
          headers: {
            ...getHeaders(),
            "api-key": getCookie("apiKey") + "-0",
            captcha: envConfig.loginUrl + ":" + recaptchaValue,
          },
          body: JSON.stringify(apiData),
        });
        const responseData = await response.json();
        if (!response.ok) {
          setIsLoading(false);
          checkAndUpdateApiKeyExpiration();
          enqueueSnackbar({
            message: responseData?.ErrorMessage || responseData,
            variant: "error",
          });
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
          navigate("/");
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        if (responseData.SuccessYN === "N") {
          setIsLoading(false);
          if (recaptchaRef.current) {
            recaptchaRef.current.reset();
          }
          enqueueSnackbar({
            message: responseData?.ErrorMessage || responseData,
            variant: "error",
          });
          //   handleOpen();
        } else if (responseData.SuccessYN === "Y") {
          enqueueSnackbar(
            isCreatingSubscriber
              ? "New subscriber created! You will be redirected"
              : "New exacc file created! You will be redirected",
            { variant: "success" }
          );

          if (isCreatingSubscriber) {
            await createSessionForExternal(
              `https://${envConfig.correspondingService}/0/en-au/subscribers/subscriber/${responseData.SubscriberId}`
            );
          } else {
            Cookies.set("databaseId", responseData.NewBusinessFileId);
            await createSessionForExternal(
              `https://${envConfig.mainServiceUrl}/${responseData.NewBusinessFileId}/en-au/mobile/dashboard`
            );
          }
        }
        setIsLoading(false);
      } catch (error) {
        if (recaptchaRef.current) {
          recaptchaRef.current.reset();
        }
        setIsLoading(false);
        console.error("Error during API call:", error);
      }

      // Handle form submission here
    },
  });

  const handleRecaptcha = (value) => {
    setRecaptchaValue(value);
  };

  const handleCancel = () => {
    setOpenCancelDialog(true);
  };

  const handleContinueEditing = () => {
    setOpenCancelDialog(false);
  };

  const handleCancelConfirm = () => {
    setOpenCancelDialog(false);
    navigate(
      // prefix +
      "/user-dashboard"
    );
  };

  useEffect(() => {
    if (abnResultsAccepted) {
      const newValues = {
        ...formik.values,
        businessName: abnResultsAccepted.EntityName,
        gstRegistered: Boolean(abnResultsAccepted.GSTRegistered === "Yes"),
        businessType: abnResultsAccepted.EntityLegalForm_Enum8,
      };

      formik.setValues(newValues);
      setAbnResultsAccepted(null);
    }
  }, [abnResultsAccepted]);

  useEffect(() => {
    setSelectedBusinessType(
      businessTypesData?.ListOfBusinessTypes.find(
        (type) => type.xxtID === formik.values.businessType
      )
    );
  }, [formik.values.businessType, businessTypesData]);

  const nameOfSubscriber = formik?.values?.subscriberAccount?.xxsName
    ? formik.values.subscriberAccount.xxsName
    : "the Subscriber";

  return (
    <>
      <Dialog open={isAbnOpen} onClose={() => setIsAbnOpen(false)}>
        <LookupAbn
          businessName={formik.values.businessName}
          abnApiResult={abnApiResult}
          setAbnApiResult={setAbnApiResult}
          abn={formik.values.abn}
          setIsAbnOpen={setIsAbnOpen}
          setAbnResultsAccepted={setAbnResultsAccepted}
        />
      </Dialog>

      <Dialog
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
      >
        <DialogContent>
          <Typography
            sx={{
              fontSize: "24px",
              fontWeight: 400,
              lineHeight: "150%",
              letterSpacing: "-0.264px",
              mb: 5,
            }}
          >
            Cancel creating Business Account?
          </Typography>
          <Typography
            sx={{
              color: isDarkMode ? "#FFF" : "#495670",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "150%",
              letterSpacing: "-0.176px",
            }}
          >
            Do you want to cancel creating Business Account?
          </Typography>
          <Typography
            sx={{
              color: isDarkMode ? "#FFF" : "#495670",
              fontSize: "16px",
              fontWeight: 400,
              lineHeight: "150%",
              letterSpacing: "-0.176px",
            }}
          >
            Your data will be lost.
          </Typography>
          <Box sx={{ mt: 5, textAlign: "right" }}>
            <Button
              sx={{
                background: "transparent",
                height: "36px",
                mr: "9px",
                "&:hover": {
                  background: "transparent",
                },
              }}
              disableRipple
              variant="secondary"
              onClick={handleContinueEditing}
            >
              Continue Editing
            </Button>
            <Button
              disableRipple
              variant="contained"
              color="primary"
              sx={
                isDarkMode
                  ? {
                      "&:hover": { color: "#FFF" },
                    }
                  : {}
              }
              onClick={handleCancelConfirm}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
      <Box
        sx={{
          borderRadius: "4px",
          bgcolor: isDarkMode ? "unset" : "#FEFDFE",
          mb: "74px",
        }}
      >
        <PageTitle
          title={
            isCreatingSubscriber
              ? "Create Subscriber Account and your own Exacc File. This will allow you to manage your Team and add additional Exacc Files."
              : "Create new Exacc File"
          }
          style={isCreatingSubscriber ? { fontSize: "20px" } : {}}
          onBack={() => setOpenCancelDialog(true)}
          displayBackIcon={true}
        />

        <Box
          sx={{
            boxShadow: "rgba(69, 90, 100, 0.1) 0px 0px 10px 0px",
            px: 5,
            pt: 6,
            pb: 10.25,
            borderRadius: "4px",
            bgcolor: isDarkMode ? "#272E3D" : "#fff",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            {!isCreatingSubscriber && (
              <Autocomplete
                sx={{
                  mt: 3,
                  maxWidth: "480px",
                  input: { height: "28px" },
                  label: { fontSize: 16, marginTop: 0 },
                  "p.Mui-error": {
                    position: "absolute",
                    bottom: -25,
                  },
                }}
                autoSelect={true}
                autoHighlight={true}
                required={Boolean(!isCreatingSubscriber)}
                options={subscribersData || []}
                getOptionLabel={(option) => option?.xxsName || ""}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    label="Subscriber account"
                    variant="outlined"
                    fullWidth
                    error={
                      formik.touched.subscriberAccount &&
                      Boolean(formik.errors.subscriberAccount?.xxsName)
                    }
                    helperText={
                      formik.touched.subscriberAccount &&
                      formik.errors.subscriberAccount?.xxsName
                    }
                  />
                )}
                value={formik.values.subscriberAccount || null} // Ensure value is null if undefined
                onChange={(event, value) => {
                  formik.setFieldValue("subscriberAccount", value || null);
                }}
              />
            )}
            {/* Business Name textbox required*/}
            <TextField
              sx={{
                mt: 4,
                maxWidth: "480px",
                display: "block",
                label: { fontSize: 16, marginTop: 0 },
                "p.Mui-error": {
                  position: "absolute",
                },
              }}
              label="Business Name"
              variant="outlined"
              required
              fullWidth
              {...formik.getFieldProps("businessName")}
              error={
                formik.touched.businessName &&
                Boolean(formik.errors.businessName)
              }
              helperText={
                formik.touched.businessName && formik.errors.businessName
              }
            />

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* ABN textbox required */}
              <TextField
                required={!!isCreatingSubscriber}
                sx={{
                  mt: 4,
                  maxWidth: "320px",
                  display: "block",
                  label: { fontSize: 16, marginTop: 0 },
                  "p.Mui-error": {
                    position: "absolute",
                  },
                }}
                label="ABN"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps("abn")}
                error={formik.touched.abn && Boolean(formik.errors.abn)}
                helperText={formik.touched.abn && formik.errors.abn}
              />
              <Link
                className="link"
                style={{
                  marginTop: "30px",
                  marginLeft: "16px",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                  color: isDarkMode ? "#AAD269" : "#849F23",
                  textDecoration: "none",
                  fontSize: "14px",
                  fontWeight: 700,
                  lineHeight: "24px",
                  letterSpacing: "0.4px",
                }}
                to="#"
                onClick={(e) => {
                  if (
                    !formik.values.abn ||
                    !AustralianBusinessNumber(formik.values.abn)
                  ) {
                    if (formik.values.businessName) {
                      window.open(
                        `https://abn.business.gov.au/Search/ResultsActive?SearchText=${formik.values.businessName.replace(
                          / /g,
                          "_"
                        )}`,
                        "_blank"
                      );
                      return;
                    }
                    if (formik.values.abn) {
                      enqueueSnackbar({
                        message: "Enter a valid ABN",
                        variant: "warning",
                      });
                      return;
                    } else {
                      enqueueSnackbar({
                        message: "Enter Business Name or ABN first",
                        variant: "warning",
                      });
                      return;
                    }
                  }
                  e.preventDefault();
                  setIsAbnOpen(true);
                }}
              >
                Lookup ABN
              </Link>
            </Box>

            <Box sx={{ display: "flex", alignItems: "center" }}>
              {/* Business Owner Email Address - textbox required, email address*/}
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "480px",
                  display: "block",
                  label: { fontSize: 16, marginTop: 0 },
                  "p.Mui-error": {
                    position: "absolute",
                  },
                }}
                required
                label="Business Owner Email Address"
                variant="outlined"
                fullWidth
                type="email"
                {...formik.getFieldProps("businessOwnerEmail")}
                error={
                  formik.touched.businessOwnerEmail &&
                  Boolean(formik.errors.businessOwnerEmail)
                }
                helperText={
                  formik.touched.businessOwnerEmail &&
                  formik.errors.businessOwnerEmail
                }
              />
              <Box sx={{ mt: 4.75, ml: 2 }}>
                <HelpTooltip
                  tooltipContent={
                    <Box
                      sx={{
                        padding: "4px 8px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: 400,
                          fontSize: "14px",
                          color: "#ffffff",
                        }}
                      >
                        You will have admin permissions as you create this
                        Business Account. If you’re not a Business Owner,
                        provide their email address here to grant an admin
                        permissions as well. This way, they can manage the
                        Business Account.
                      </Typography>
                    </Box>
                  }
                />
              </Box>
            </Box>

            {/* Contact Name - textbox, not required */}
            {/* {isCreatingSubscriber && (
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "480px",
                  display: "block",
                  label: { fontSize: 16, marginTop: 0 },
                }}
                label="Contact Name"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps("contactName")}
              />
            )} */}

            {/* Contact Email Address - textbox, not required */}
            {/* {isCreatingSubscriber && (
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "480px",
                  display: "block",
                  label: { fontSize: 16, marginTop: 0 },
                }}
                label="Contact Email Address"
                variant="outlined"
                fullWidth
                type="email"
                {...formik.getFieldProps("contactEmail")}
              />
            )} */}

            {/* Contact Phone Number, not required */}
            {/* {isCreatingSubscriber && (
              <TextField
                sx={{
                  mt: 4,
                  maxWidth: "480px",
                  label: { fontSize: 16, marginTop: 0 },
                }}
                label="Contact Phone Number"
                variant="outlined"
                fullWidth
                {...formik.getFieldProps("contactPhone")}
              />
            )} */}

            <Autocomplete
              sx={{
                mt: 4,
                mb: 4,
                maxWidth: "480px",
                input: { height: "28px" },
                label: { fontSize: 16, marginTop: 0 },
                "p.Mui-error": {
                  position: "absolute",
                  bottom: -25,
                },
              }}
              required
              value={selectedBusinessType || null}
              options={businessTypesData?.ListOfBusinessTypes || []}
              getOptionLabel={(option) => option?.xxtTypeName || ""}
              autoSelect={true}
              autoHighlight={true}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  label="Business Type"
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps("businessType")}
                  error={
                    formik.touched.businessType &&
                    Boolean(formik.errors.businessType)
                  }
                  helperText={
                    formik.touched.businessType && formik.errors.businessType
                  }
                />
              )}
              onChange={(event, value) => {
                formik.setFieldValue("businessType", value?.xxtID || null);
              }}
            />

            {/* Country of Residence - Autocomplete select, required if above switch is true */}
            {/*<Autocomplete
              sx={{
                mt: isCreatingSubscriber ? 3 : 2,
                mb: "12px",
                maxWidth: "480px",
                input: { height: "28px" },
                label: { fontSize: 16, marginTop: 0 },
                "p.Mui-error": {
                  position: "absolute",
                  bottom: -25,
                },
              }}
              required
              options={countriesData?.ListOfCountries || []}
              getOptionLabel={(option) => option?.ctrName}
              defaultValue={{ ctrID: 14, ctrCode: "AUS", ctrName: "Australia" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Country of Residence"
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps("countryOfResidence")}
                  error={
                    formik.touched.countryOfResidence &&
                    Boolean(formik.errors.countryOfResidence)
                  }
                  helperText={
                    formik.touched.countryOfResidence &&
                    formik.errors.countryOfResidence
                  }
                />
              )}
              onChange={(event, value) => {
                formik.setFieldValue("countryOfResidence", value);
              }}
            />
            */}
            {/* Checkbox with label "This business is GST registered" */}
            <Box
              sx={{
                margin: "24px -20px 8px -20px",
                padding: "0 20px 4px 20px",
                boxShadow: "rgba(1, 1, 1, 0.4) 0px 0px 3px",
                borderRadius: "8px",
              }}
            >
              <Typography sx={{ paddingTop: "12px" }}>
                Your own Exacc Account:
              </Typography>
              <FormControlLabel
                sx={{
                  mt: isCreatingSubscriber ? "12px" : 1,
                  label: { fontSize: 16, marginTop: 0 },
                }}
                control={
                  <Checkbox
                    checked={formik.values.gstRegistered}
                    onChange={formik.handleChange}
                    name="gstRegistered"
                  />
                }
                label="This business is GST registered"
              />

              {/* Checkbox with label "Allow Team members of Premium Accounting Ltd to access business details automatically with Admin privileges" */}
              {nameOfSubscriber && !isCreatingSubscriber && (
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    sx={{ mt: 1 }}
                    control={
                      <Checkbox
                        checked={formik.values.allowTeamAccess}
                        onChange={formik.handleChange}
                        name="allowTeamAccess"
                      />
                    }
                    label={`Allow Team members of ${nameOfSubscriber} to access business details automatically with Admin privileges`}
                  />
                  <Box sx={{ mt: "15.8px" }}>
                    <HelpTooltip
                      tooltipContent={
                        <Box
                          sx={{
                            padding: "4px 8px",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: 400,
                              fontSize: "14px",
                              color: "#ffffff",
                            }}
                          >
                            Selecting this option will allow your Subscriber
                            Account team members to gain access with Admin
                            privileges without any invitation or further
                            confirmation. They can do it by selecting the
                            company and clicking 'Request access'. If this
                            option is not selected, the same action will send
                            you a request to approve the request.
                          </Typography>
                        </Box>
                      }
                    />
                  </Box>
                </Box>
              )}

              {formik.values.allowTeamAccess && (
                <FormControlLabel
                  sx={{ mt: 1, mb: "4px", display: "block" }}
                  control={
                    <Checkbox
                      checked={formik.values.allowTeamInviteExternal}
                      onChange={formik.handleChange}
                      name="allowTeamInviteExternal"
                    />
                  }
                  label={`Allow Team members of ${nameOfSubscriber} to invite external users`}
                />
              )}
            </Box>
            {/* Switch with label "This Subscriber Account is a tax/BAS agent" */}
            {isCreatingSubscriber && (
              <FormControlLabel
                sx={{ mt: "4px" }}
                control={
                  <Switch
                    checked={formik.values.taxAgent}
                    onChange={formik.handleChange}
                    name="taxAgent"
                  />
                }
                label="This Subscriber Account is a tax/BAS agent"
              />
            )}
            {isCreatingSubscriber && (
              <Typography
                sx={{
                  color: isDarkMode ? "#FFF" : "#252525",
                  mt: 1,
                  fontWeight: 300,
                  lineHeight: "175%",
                  letterSpacing: "0.15px",
                  mb: 2,
                }}
              >
                You will be able to lodge various reports (e.g. TPAR, STP) as
                Tax Agent, skipping registration of the software with the ATO
                per each business. This ability will be passed to Members of
                this subscription as a permission (next step). You can add these
                details later.
              </Typography>
            )}
            {formik.values.taxAgent && (
              <>
                <TextField
                  sx={{
                    mt: 2,
                    maxWidth: "480px",
                    display: "block",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Registered Agent Number"
                  variant="outlined"
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("registeredAgentNumber")}
                />

                <TextField
                  sx={{
                    mt: 4,
                    maxWidth: "480px",
                    display: "block",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Registered Agent Name"
                  variant="outlined"
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("registeredAgentName")}
                />

                {/* Contact Phone Number, not required */}
                <TextField
                  sx={{
                    mt: 4,
                    maxWidth: "480px",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Registered Agent Email Address"
                  variant="outlined"
                  fullWidth
                  {...formik.getFieldProps("registeredAgentEmailAddress")}
                />

                <TextField
                  sx={{
                    mt: 4,
                    maxWidth: "480px",
                    display: "block",
                    label: { fontSize: 16, marginTop: 0 },
                  }}
                  label="Registered Agent Phone Number"
                  variant="outlined"
                  fullWidth
                  type="text"
                  {...formik.getFieldProps("registeredAgentPhoneNumber")}
                />
              </>
            )}
            {/* Buttons "Cancel" and "Create" */}
            <Box sx={{ mt: 2 }}>
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={
                  window.location.origin.includes("login")
                    ? process.env.REACT_APP_SITE_KEY_LOGIN_OLIVS
                    : window.location.origin.includes("olivs.app")
                    ? process.env.REACT_APP_SITE_KEY_OLIVS_APP
                    : window.location.origin.includes(".ssx.")
                    ? process.env.REACT_APP_SITE_KEY_SSX
                    : process.env.REACT_APP_SITE_KEY_MY_EXACC
                }
                onChange={handleRecaptcha}
                theme={isDarkMode ? "dark" : "light"}
              />
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Button
                sx={{
                  background: "transparent",
                  height: "36px",
                  mr: "9px",
                  "&:hover": {
                    background: "transparent",
                  },
                }}
                disableRipple
                variant="secondary"
                onClick={handleCancel}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isLoading}
                sx={{ ml: 2, minHeight: "36.5px", minWidth: "77px" }}
              >
                {isLoading ? (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: (theme) => theme.palette.primary.contrastText,
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      marginTop: "-12px",
                      marginLeft: "-12px",
                    }}
                  />
                ) : (
                  "Create"
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default CreateBusinessAccount;
