import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useRef } from "react";
import ThemeToggle from "../../ThemeToggle/ThemeToggle";
import { useTheme } from "../../../context/ThemeContext";
import { LOGIN_API_OLIVS_APP_URL } from "../../../utils/common";
import Logo from "../../../assets/exacc-logo.png";
import { enqueueSnackbar } from "notistack";
import envConfig from "../../../config";

const TwoFAPanel = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const [isTallScreen, setIsTallScreen] = useState(window.innerHeight > 1015);
  const [userEmail, setUserEmail] = useState();
  const [apiKey, setApiKey] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [loginSecuredHash, setLoginSecuredHash] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [smsSent, setSmsSent] = useState(false);

  const [redirectBackTo, setRedirectBackTo] = useState("");
  const [code, setCode] = useState("");
  const [open, setOpen] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const inputRef = useRef();

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSuccess(false);
  };

  const handleVerification = async () => {
    setLoading(true);

    if (code.length === 6) {
      try {
        const verificationUrl =
          LOGIN_API_OLIVS_APP_URL +
          `en-au/system/validate-2fa-code?BaseHostURL=${envConfig.loginUrl}`;

        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "127.0.0.2",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "session-id": sessionId,
          "browser-agent": window.navigator.userAgent,
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": apiKey + "-0",
        };

        const verificationData = JSON.stringify({
          UserLogin2FACode: code,
          RememberMeYN: rememberMe ? "Y" : "N",
        });

        const verificationResponse = await fetch(verificationUrl, {
          method: "POST",
          headers: headers,
          body: verificationData,
        });

        const verificationJson = await verificationResponse.json();
        if (verificationJson.SuccessYN === "Y") {
          const newSessionApiUrl =
            LOGIN_API_OLIVS_APP_URL +
            `en-au/session-management/create-new-session-instance?BaseHostURL=${envConfig.loginUrl}`;

          const sessionHeaders = {
            "Content-Type": "application/json",
            Accept: "application/json",
            "olivs-api-real-ip": "127.0.0.2",
            "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
            "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
            "browser-agent": window.navigator.userAgent,
            "session-id": sessionId,
            "api-key": apiKey + "-0",
          };

          const initialTokenData = JSON.stringify({
            LoginSecuredHash: loginSecuredHash,
            DatabaseId: 0,
            CurrentHost: `https://${envConfig.loginUrl}`,
            LoginPageUrl: `https://${envConfig.loginUrl}`,
            RedirectPageUrl: redirectBackTo
              ? redirectBackTo
              : `https://${envConfig.loginUrl}/user-dashboard`,
            IpAddress: "127.0.0.2",
            UserAgentAkaBrowserDetails: navigator.userAgent,
            ReferrerUrl: `https://${envConfig.loginUrl}/`,
          });

          const sessionManagementResponse = await fetch(newSessionApiUrl, {
            method: "POST",
            headers: sessionHeaders,
            body: initialTokenData,
          });

          const sessionManagementJson = await sessionManagementResponse.json();
          if (sessionManagementJson?.successYN === "Y") {
            if (redirectBackTo) {
              window.location = sessionManagementJson?.redirectPageUrl;
            } else {
              window.location = sessionManagementJson?.redirectPageUrl;
            }
          } else {
            setLoading(false);
            setCode("");
            inputRef.current.focus();
            console.log(sessionManagementJson);
            setErrorMessage(sessionManagementJson?.errorMessage);
            setOpen(true);
          }
        } else {
          if (
            verificationJson === "Your API Key expired" ||
            verificationJson === "Your session expired. Please log in again."
          ) {
            enqueueSnackbar({
              message: "Session expired, please log in again.",
              variant: "error",
            });
            navigate("/");
            return;
          }
          setLoading(false);
          setCode("");
          inputRef.current.focus();
          setErrorMessage(verificationJson.ErrorMessage);
          setOpen(true);
        }
      } catch (error) {
        setCode("");
        inputRef.current.focus();
        setLoading(false);
        setErrorMessage(
          "Error occurred while processing the code. Please try again."
        );
        setOpen(true);
      }
    } else {
      setLoading(false);
      // Empty code
    }
  };

  const sendCodeViaSMS = async () => {
    try {
      setSmsSent(true);
      const smsUrl = `https://${envConfig.apiDev1}/api/v3/en-au/user2fa/send-code-as-sms?BaseHostURL=${envConfig.loginUrl}`;

      const headers = {
        Accept: "application/json",
        "olivs-api-real-ip": "127.0.0.2",
        "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
        "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
        "session-id": sessionId,
        "browser-agent": window.navigator.userAgent,
        "api-key": apiKey + "-0",
      };

      setTimeout(async () => {
        const smsResponse = await fetch(smsUrl, {
          method: "POST",
          headers: headers,
        });

        const smsJson = await smsResponse.json();
        if (smsJson?.SmsSentYN === "Y") {
          setLoading(false);
        } else {
          setSmsSent(false);
          setErrorMessage(smsJson?.ErrorMessage);
          setOpen(true);
        }
      }, 10000);
    } catch (error) {
      setSmsSent(false);
      setErrorMessage(
        "Error occurred while sending the code. Please try again."
      );
      setOpen(true);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsTallScreen(window.innerHeight > 1015);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (location.state?.email && !userEmail) {
      setUserEmail(location.state.email);
      setApiKey(location.state.apiKey);
      setSessionId(location.state.sessionId);
      setLoginSecuredHash(location.state.loginSecuredHash);
      setRedirectBackTo(location.state.redirectBackTo);
    } else if (!location?.state?.email && !userEmail) {
      navigate("/");
    }
  }, [location.state]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.altKey && event.key === "r") {
        setRememberMe((prevRememberMe) => !prevRememberMe);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
      }}
    >
      <Snackbar
        sx={{ zIndex: "9999" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage || "Something went wrong. Please try again"}
        </Alert>
      </Snackbar>
      <Snackbar
        sx={{ zIndex: "9999" }}
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          The code has been sent via SMS
        </Alert>
      </Snackbar>
      <img
        onClick={() => {
          navigate(`/`);
        }}
        height={50}
        style={{ cursor: "pointer", marginTop: "64px" }}
        src={Logo}
        alt="Logo"
      />
      <Box sx={{ mt: isTallScreen ? "188px" : "18%" }}>
        <Typography
          variant="h4"
          component="h1"
          sx={{
            color: isDarkMode ? "#7D8CAA" : "#495670",
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: "Inter",
            fontSize: "28px",
            fontStyle: "normal",
            fontWeight: 300,
            lineHeight: "133.4%",
          }}
        >
          {isMobile ? "Enter 2FA code" : "Two-factor authentication"}
        </Typography>

        <Box
          sx={{
            mt: "21px",
            borderBottom: "1px solid #EDEDED",
            paddingBottom: "39px",
            width: isMobile ? "100%" : isTablet ? 380 : 480,
          }}
        >
          {isMobile ? (
            <></>
          ) : (
            <Typography>
              Enter the code from your authentication app for {userEmail}
            </Typography>
          )}

          <TextField
            type="tel"
            variant="outlined"
            margin="normal"
            value={code}
            onChange={(e) => {
              const newValue = e.target.value;
              if (/^\d*$/.test(newValue) && newValue.length <= 6) {
                setCode(newValue);
              }
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" && !loading) {
                handleVerification();
              }
            }}
            inputProps={{
              style: {
                textAlign: "center",
                fontSize: "16px",
                letterSpacing: "5px",
              },
            }}
            sx={{
              width: "100%",
              input: { minHeight: "39px" },
              "input::-webkit-outer-spin-button, input::-webkit-inner-spin-button":
                {
                  WebkitAppearance: "none",
                  margin: 0,
                },

              "input[type=number]": {
                MozAppearance: "textfield",
              },
            }}
            inputRef={inputRef}
          />

          <Link
            className={"link " + smsSent ? "disabled" : ""}
            onClick={() => {
              if (!smsSent) sendCodeViaSMS();
            }}
            aria-disabled={smsSent}
            style={{
              color: smsSent ? "#888888" : isDarkMode ? "#AAD269" : "#849F23",
              marginTop: 0,
              cursor: smsSent ? "default" : "pointer",
              fontSize: "14px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
          >
            Send code via SMS
          </Link>

          <Box>
            <FormControlLabel
              sx={{ mt: 1, span: { fontSize: 14 } }}
              control={
                <Checkbox
                  name="rememberMe"
                  checked={rememberMe}
                  onChange={() =>
                    setRememberMe((prevRememberMe) => !prevRememberMe)
                  }
                />
              }
              label={
                <span>
                  <u>R</u>emember Me
                </span>
              }
            />
          </Box>

          <Button
            variant="contained"
            color="primary"
            fullWidth
            disabled={loading || code.length !== 6}
            onClick={handleVerification}
            sx={{ mt: 3, mb: 0, width: isMobile ? "100%" : 480 }}
          >
            Login
          </Button>
        </Box>
        <Box
          sx={{
            mt: 2,
            mb: 4,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: isMobile ? "100%" : isTablet ? 380 : 480,
          }}
        >
          <Link
            className="link"
            to={"/login"}
            style={{
              color: isDarkMode ? "#AAD269" : "#849F23",
              marginTop: 0,
              cursor: "pointer",
              fontSize: "14px",
              fontWeight: 700,
              letterSpacing: "0.4px",
              textTransform: "capitalize",
              textDecoration: "none",
            }}
          >
            Log out
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ThemeToggle
          titlefontSize={16}
          title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
        />
      </Box>
    </Box>
  );
};

export default TwoFAPanel;
