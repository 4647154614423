import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Snackbar,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Link, useNavigate, useParams } from "react-router-dom";
import ThemeToggle from "../../ThemeToggle/ThemeToggle";
import { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTheme } from "../../../context/ThemeContext";
import { deleteAllCookies, getLoginSecured } from "../../../utils/common";
import Logo from "../../../assets/exacc-logo.png";
import { useSnackbar } from "notistack";
import { LOGIN_API_OLIVS_APP_URL } from "../../../utils/api";
import envConfig from "../../../config";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email format")
    .required("Email is required"),
  password: Yup.string().required("Password is required"),
  rememberMe: Yup.boolean(),
});

const LoginPanel = () => {
  const { isDarkMode } = useTheme();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const { enqueueSnackbar } = useSnackbar();

  const noFullFit = useMediaQuery("(max-width: 1300px)");
  const isTablet = useMediaQuery("(max-width: 1028px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [isTallScreen, setIsTallScreen] = useState(window.innerHeight > 1015);

  const [redirectBackTo, setRedirectBackTo] = useState("");
  const [open, setOpen] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      rememberMe: false,
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      deleteAllCookies();
      setOpen(false);
      try {
        const headers = {
          "Content-Type": "application/json",
          Accept: "application/json",
          "olivs-api-real-ip": "127.0.0.2",
          "olivs-api-computer-name": "EC2AMAZ-QF1CQAD",
          "olivs-root-password": "OlivsWillBeBetterThanBTMSoft!",
          "api-key": "OlivsAuth",
        };
        const generateTokenUrl =
          LOGIN_API_OLIVS_APP_URL +
          `en-au/system/generate-new-api-token-details?BaseHostURL=${envConfig.loginUrl}`;
        const loginSecured = getLoginSecured(values.email, values.password);
        const generateTokenData = JSON.stringify({
          LoginSecuredHash: loginSecured,
        });

        const generateTokenResponse = await fetch(generateTokenUrl, {
          method: "POST",
          headers: {
            ...headers,
            "browser-agent": window.navigator.userAgent,
          },

          body: generateTokenData,
        });

        const generateTokenJson = await generateTokenResponse.json();

        console.log("generateTokenJson", generateTokenJson);

        if (generateTokenJson?.LoginEmailVerifiedYN === "N") {
          navigate("/verify-email", {
            state: {
              firstName: generateTokenJson.FirstName,
              email: values.email,
              loginSecured: getLoginSecured(values.email, values.password),
            },
          });
        } else if (generateTokenJson?.MobPhoneNoVerifiedYN === "N") {
          navigate("/verify-phone", {
            state: {
              loginSecuredHash: getLoginSecured(values.email, values.password),
              phone: generateTokenJson?.MobPhoneNo,
              apiKey: generateTokenJson?.ApiKey + "-0",
              sessionId: generateTokenJson?.SessionID,
            },
          });
        } else if (generateTokenJson?.ApiKey) {
          navigate("/mfa", {
            state: {
              redirectBackTo: redirectBackTo,
              email: values.email,
              apiKey: generateTokenJson?.ApiKey,
              sessionId: generateTokenJson?.SessionID,
              ip: "127.0.0.2",
              loginSecuredHash: loginSecured,
            },
          });
        } else if (typeof generateTokenJson === "string") {
          console.log("2");
          enqueueSnackbar({ message: generateTokenJson, variant: "error" });
          setOpen(true);
        } else if (
          generateTokenJson?.ErrorMessage ||
          generateTokenJson?.errorMessage
        ) {
          console.log("3");
          enqueueSnackbar({
            message: generateTokenJson?.ErrorMessage,
            variant: "error",
          });
        }
      } catch (error) {
        console.log("4");
        console.error("Error:", error);
        enqueueSnackbar({
          message: "Error occurred while logging in. Please try again.",
          variant: "error",
        });
      } finally {
        // Reset the form and loading state
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    const handleResize = () => {
      setIsTallScreen(window.innerHeight > 1015);
    };

    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get("redirectBackTo");
    if (redirectUrl) {
      setRedirectBackTo(redirectUrl);
      urlParams.delete("redirectBackTo");
      window.history.replaceState(
        {},
        "",
        `${window.location.pathname}?${urlParams.toString()}`
      );
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box
      sx={{
        mx: isMobile ? "auto" : "unset",
        ml: isMobile ? "auto" : isTablet ? "48px" : noFullFit ? 10 : 16,
        px: isMobile ? 5 : "unset",
        pt: isMobile ? "140px" : isTallScreen ? "290px" : "200px",
      }}
    >
      <img
        onClick={() => {
          navigate(`/`);
        }}
        height={50}
        style={{ cursor: "pointer", position: "absolute", top: "64px" }}
        src={Logo}
        alt="Logo"
      />
      <Snackbar
        sx={{ zIndex: 9999999 }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity="error"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {errorMessage}
        </Alert>
      </Snackbar>
      <Typography
        variant="h4"
        component="h1"
        sx={{
          color: isDarkMode ? "#7D8CAA" : "#495670",
          fontFeatureSettings: "'clig' off, 'liga' off",
          fontFamily: "Inter",
          fontSize: "28px",
          fontStyle: "normal",
          fontWeight: 300,
          lineHeight: "133.4%",
        }}
      >
        Welcome!
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <TextField
          autoComplete="chrome-off"
          fullWidth
          variant="outlined"
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Email Address"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email ? formik.errors.email : ""}
          sx={{
            marginTop: isMobile ? 3 : 7,
            width: isMobile ? "100%" : isTablet ? 380 : 480,
            label: { fontSize: 16, marginTop: 0 },
          }}
        />

        <TextField
          autoComplete="chrome-off"
          fullWidth
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          margin="normal"
          type="password"
          label="Password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password ? formik.errors.password : ""}
          sx={{
            marginTop: 2,
            width: isMobile ? "100%" : isTablet ? 380 : 480,
            label: { fontSize: 16, marginTop: 0 },
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              formik.handleSubmit();
            }
          }}
        />
      </Box>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: isMobile ? "100%" : isTablet ? 380 : 480,
          marginTop: "16px",
        }}
      >
        <div></div>
        <Link
          className="link"
          to={"/reset-password"}
          style={{
            color: isDarkMode ? "#AAD269" : "#849F23",
            marginTop: 2,
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: 700,
            letterSpacing: "0.4px",
            textTransform: "capitalize",
            textDecoration: "none",
          }}
        >
          Forgot Password?
        </Link>
      </div>
      <Box
        sx={{
          width: isMobile ? "100%" : isTablet ? 380 : 480,
          borderBottom: "1px solid #EDEDED",
          paddingBottom: "39px",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={formik.handleSubmit}
          sx={{ marginTop: 2, width: isMobile ? "100%" : isTablet ? 380 : 480 }}
        >
          Login
        </Button>
      </Box>
      <Box
        sx={{
          mt: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: isMobile ? "100%" : isTablet ? 380 : 480,
        }}
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: 14,
          }}
        >
          Don’t have Exacc account yet?
        </Typography>
        <Link
          className="link"
          to={`/register`}
          style={{
            color: isDarkMode ? "#AAD269" : "#849F23",
            marginLeft: 24,
            marginTop: 0,
            cursor: "pointer",
            fontSize: "14px",
            fontWeight: 700,
            letterSpacing: "0.4px",
            textTransform: "capitalize",
            textDecoration: "none",
          }}
        >
          Register Now
        </Link>
      </Box>
      <Box
        sx={{
          position: "relative",
          width: isMobile ? 245 : isTablet ? 380 : 480,
          my: 3,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ThemeToggle
          titlefontSize={16}
          title={isDarkMode ? "Switch to light mode" : "Switch to dark mode"}
        />
      </Box>
    </Box>
  );
};

export default LoginPanel;
